import { Controller } from 'stimulus';
import IframeResizer from 'iframe-resizer';

export default class extends Controller {
  connect() {
    const iframe = this.element;

    iFrameResize(iframe);
  }
}
